import { parse, intervalToDuration, format } from 'date-fns';

import { convertedDatesToCurrentTimezone } from '@/shared/utils/convertedDatesToCurrentTimezone';
import { convertUTCFormatToStartEndDateString } from '@/shared/utils/convertUTCFormatToStartEndDateString';
import { typeConverter } from '@/shared/utils/typeConverter';
import { yesNoToBoolean } from '@/shared/utils/yesNoToBoolean';

import { EVENT_TYPES_BACKEND } from '@constants/colorsByEventTypes';
import { YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS } from '@constants/dateFormats';

const getStartAndEnd = (item: any) => {
  const { startDate, startTime, endDate, endTime } = item;

  return {
    end: parse(`${endDate} ${endTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
    start: parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
  };
};

const getAllDay = (item: any) => {
  const booleanAllDay = yesNoToBoolean(item.allDay);
  if(booleanAllDay) {
    return booleanAllDay;
  }

  let allDay = false;

  const { startDate, startTime, endDate, endTime } = convertUTCFormatToStartEndDateString({
    startDate: item.startDate,
    startTime: item.startTime,
    endDate: item.endDate,
    endTime: item.endTime
  });

  try {
    const { days, years, months } = intervalToDuration({
      end: parse(`${endDate} ${endTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
      start: parse(`${startDate} ${startTime}`, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS, new Date()),
    });

    if((days && days >= 1) || (months && months >= 1) || (years && years >= 1)) {
      allDay = true;
    }
  } catch (error) {
    console.error(error);
  }

  return allDay;
};

const findStatusLabelById = (arr: any, id: any) => {
  if(!Array.isArray(arr)) {
    return null;
  }
  const found = arr.find(item => item.id === id);
  return found ? found.option : null;
};

const DEFAULT_CREATION_TIMEZONE = 'America/Chicago';
export const calendarNormalizer = (resp: any, statusData: any) => {
  if(!resp) {
    return [];
  }

  const result = resp.map((item: any) => {
    const dates = convertedDatesToCurrentTimezone({
      createdDate: item.createdDate || new Date(`${item.startDate}T${item.startTime}Z`),
      startDate: item.startDate,
      startTime: item.startTime,
      endDate: item.endDate,
      endTime: item.endTime,
      allDay: yesNoToBoolean(item.allDay),
      createdTimeZone: item.createdTimeZone || DEFAULT_CREATION_TIMEZONE
    });
    
    const [ startDate, startTime ] = format(dates.startDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');
    const [ endDate, endTime ] = format(dates.endDate, YEAR_MONTH_DAY_HOURS_MINUTES_SECONDS).split(' ');

    return {
      ...getStartAndEnd({ startDate, startTime, endDate, endTime }),
      allDay: yesNoToBoolean(item.allDay),
      title: item.name,
      resource: {
        allDay: yesNoToBoolean(item.allDay),
        clients: item.clients,
        createdDate: item.createdDate,
        createdTimeZone: item.createdTimeZone,
        description: item.description,
        editAllow: item.editAllow,
        endDate,
        endTime,
        forDay: item?.forDay,
        id: item.id,
        isAllDayGrid: getAllDay(item),
        name: item.name,
        primaryClientId: item.primaryClientId,
        primaryContactId: item.primaryContactId,
        recurring: yesNoToBoolean(item.recurring),
        recurringEndDate: item.recurringEndDate,
        recurringFrequency: item.recurringFrequency,
        startDate,
        startTime,
        statusId: item.statusId,
        statusLabel: findStatusLabelById(statusData, item.statusId),
        subCategoryId: item.subCategoryId,
        isEditable: item.type !== EVENT_TYPES_BACKEND.holiday && item.editAllow,
        type: typeConverter({
          isBackend: true,
          type: item.type,
        }),
      }
    };
  });

  return result;
};
