import { getTimezoneOffset } from 'date-fns-tz';

export const convertedDatesToCurrentTimezone = (event: any) => {
  const { createdDate, startDate, startTime, endDate, endTime, allDay, createdTimeZone } = event;

  const startDateString = getDateFromString(startDate, startTime);
  const endDateString = getDateFromString(endDate, endTime);

  const utcOffset = getTimezoneOffset(createdTimeZone, createdDate);

  if(allDay) {
    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  }

  return {
    startDate: getConvertedDate(startDateString, createdTimeZone, utcOffset),
    endDate: getConvertedDate(endDateString, createdTimeZone, utcOffset),
  };
};

const getDateFromString = (date: string, time: string) => {
  const dateString = date + 'T' + time + '.000';
  return new Date(dateString);
};

const getConvertedDate = (date: Date, createdTimeZone: string, utcOffset: number) => {
  const localTimezoneOffset = date.getTimezoneOffset() * 60 * 1000;
  const createdTimezoneOffset = getTimezoneOffset(createdTimeZone, date) * -1;
  const differenceLocalAndCreatedTimezoneOffset = localTimezoneOffset - createdTimezoneOffset;

  const eventLocalTime = new Date(new Date(date).getTime() + utcOffset);

  const eventDate = eventLocalTime.getTime() - differenceLocalAndCreatedTimezoneOffset;

  return new Date(eventDate);
};
